import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Floriselscript.ttf'
import './fonts/corinthia.ttf'
import {
  BrowserRouter, Navigate, Route, Routes
} from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/">
          <Route index element={<Navigate to="/invitations/" replace />} />
          <Route path="/invitations/:invitationId" element={<App />} />
          <Route path="/invitations/" element={<App />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
