import React, { useState } from 'react';
import './App.css';
import { useParams } from 'react-router-dom';


function App() {
  let { invitationId } = useParams();
  const [invitationTitle, setInvitationTitle] = useState('Дорогие наши родные и друзья!');
  const [invitationText, setInvitationText] = useState('Будем рады разделить с вами один из самых важных дней нашей жизни и пригласить вас на уютный радостный праздник по случаю создания нашей семьи!');

  if (invitationId) {
    fetch(`https://api.wedding.chertilin.com/invitations/${invitationId}`)
      .then(
        (res) => {
          res.json()
            .then((data) => {
              setInvitationTitle(data.title_text || 'Дорогие наши родные и друзья!');
              setInvitationText(data.p_text || 'Будем рады разделить с вами один из самых важных дней нашей жизни и пригласить вас на уютный радостный праздник по случаю создания нашей семьи!');
            })
            .catch((e) => {
              console.log(e);
            })
        }
      )
      .catch((e) => {
        console.log(e)
      })
  }
  const getSignature = () => (
    <div className={'signature'}>
      <div>
        Андрей
      </div>
      <div>
        и
      </div>
      <div>
        Марина
      </div>
    </div>
  );
  return (
    <div className="App">
      <div className="background-left" />
      <div className="background-right" />
      <div className="container">
        <div className="content-block">
          <h1 className="title-h1">
            Приглашение на свадьбу
          </h1>
          <h2 className="title-h2">
            Cуббота 14 cентября
          </h2>
          {getSignature()}
        </div>
        <div className="content-block">
          <h2 className="title-h2">
            {invitationTitle}
          </h2>
          <p className="text">
            {invitationText}
          </p>
        </div>
        <div className="content-block">
          <h2 className="title-h2 ">
            Место проведения
          </h2>
          <div className="address-block">
            <p className="title-h3">
              Кафе "Гранж"
            </p>
            <p className="text">
              Москва, Зубовский бульвар, 22/39
            </p>
            <p className="text">
              метро Парк культуры
            </p>
          </div>
          <a
            className="button"
            href="https://yandex.ru/maps/-/CDcyiG1I"
          >
            На карте
          </a>
        </div>
        <div className="content-block">
          <h2 className="title-h2">
            Тайминг
          </h2>
          <div className="text">
            Сбор гостей
          </div>
          <div className="text">
            15:30 - 16:00
          </div>
          <div className="text">
            Основная программа
          </div>
          <div className="text">
            16:00 - 21:00
          </div>
          <div className="text">
            Музыка, dj, танцы
          </div>
          <div className="text">
            21:00 - 23:00
          </div>
        </div>
        <div className="content-block">
          <h2 className="title-h2">
            DressCode
          </h2>
          <p className="text">
            Для нас главное - Ваше присутствие!
          </p>
          <p className="text">
            Но мы будем рады, если вы поддержите нас красивыми нарядами в нейтральных, не ярких тонах,
            праздничным настроением, Вашими улыбками и объятиями.
          </p>
        </div>
        <div className="content-block">
          <h2 className="title-h2">
            Фотографии и видео со свадьбы
          </h2>
          <p className="text">
            Мы создали канал в Telegram, куда выложим все фото и видео со свадьбы.
            Будем рады, если вы пришлете туда фото, сделанные вами на нашем празднике.
          </p>
          <a
            className="button"
            href="https://t.me/+Ewplr-q3lEJjYjEy"
          >
            Перейти
          </a>
        </div>
        <div className="content-block">
          <h2 className="title-h2">
            Мы очень ждем Вас на нашем празднике!
          </h2>
        </div>
      </div>
    </div>
  );
}

export default App;
